import styled from 'styled-components';
import { Row, Col } from 'antd';
import Link from 'next/link';
import _ from 'lodash';
import { useRouter } from 'next/router';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import LoginForm from './form';

const Login = () => {
  const router = useRouter();
  const redirectTo = _.get(router, 'query.redirectTo');
  const handleSignup = () => {
    router.push({
      pathname: '/signup',
      query: {
        ...(redirectTo && { redirect: redirectTo })
      }
    });
  };
  return (
    <Layout withFooter={false}>
      <StyledContainer>
        <Row>
          <CenteredCol span={24} md={12}>
            <LoginForm />
            <Helper>
              <FindPassword>
                <Link href="/find-password">
                  <a>비밀번호 찾기</a>
                </Link>
              </FindPassword>
              <EntityCode>&nbsp;/&nbsp;</EntityCode>
              <Signup>
                <Div onClick={handleSignup}>
                  <a>회원가입</a>
                </Div>
              </Signup>
            </Helper>
          </CenteredCol>
        </Row>
      </StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled(Container)`
  text-align: center;
`;

const Helper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CenteredCol = styled(Col)`
  height: 100vh;
  ${props => props.theme.device.md} {
    margin: 0 ${props => ((1 - props.md / props.span) * 100) / 2}%;
  }
`;

const FindPassword = styled.div`
  margin-bottom: 3rem;
  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: #3c3c3c;
    vertical-align: middle;
    ${props => props.theme.device.md} {
      font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
    }
  }
`;

const EntityCode = styled.span`
  font-size: 1.6rem;
`;

const Signup = styled.div`
  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: #3c3c3c;
    vertical-align: middle;
    ${props => props.theme.device.md} {
      font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
    }
  }
  span {
    font-size: 1.6rem;
    font-weight: 700;
    color: #2b2c2f;
    margin-right: 0.5rem;
    vertical-align: middle;

    ${props => props.theme.device.md} {
      font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
    }
  }
`;

const Div = styled.div``;

export default Login;
